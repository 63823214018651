var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fsc-simple-card',{staticClass:"h-100",attrs:{"content-class":"py-1 pt-4"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('b-row',{staticClass:"form-group d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-weight-bold mb-1"},[_vm._v(" "+_vm._s(_vm.$t("general.mail_box"))+" ")])]),_c('b-col',{attrs:{"md":"7"}},[_c('fsc-multiselect',{attrs:{"options":_vm.emailsConfigByUser,"label":"email","preselectFirst":true,"clearable":false,"disabled":_vm.hasNotPermissionWrite},on:{"input":_vm.onInputMailBox},model:{value:(_vm.mailBox),callback:function ($$v) {_vm.mailBox=$$v},expression:"mailBox"}})],1),_c('b-col',{attrs:{"sm":"1"}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('create-button',{attrs:{"disabled":_vm.hasNotPermissionWrite},on:{"click":function($event){return _vm.onCreate()}}}),_c('remove-button',{attrs:{"disabled":_vm.hasNotPermissionWrite},on:{"click":function($event){return _vm.onDelete()}}})],1)])],1),_c('b-row',{staticClass:"form-group d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-weight-bold mb-0"},[_vm._v(" Absender * ")])]),_c('b-col',{attrs:{"md":"7"}},[_c('validation',{attrs:{"validations":_vm.$v.sender},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.sender),callback:function ($$v) {_vm.sender=$$v},expression:"sender"}})}}])})],1)],1),_c('b-row',{staticClass:"form-group d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("auth.email"))+" * ")])]),_c('b-col',{attrs:{"md":"7"}},[_c('validation',{attrs:{"validations":_vm.$v.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})}}])})],1)],1),_c('b-row',{staticClass:"form-group d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("configuration.username"))+" * ")])]),_c('b-col',{attrs:{"md":"7"}},[_c('validation',{attrs:{"validations":_vm.$v.username},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})}}])})],1)],1),_c('b-row',{staticClass:"form-group d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("auth.password"))+" * ")])]),_c('b-col',{attrs:{"md":"7"}},[_c('validation',{attrs:{"validations":_vm.$v.password},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"password","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})}}])})],1)],1),_c('b-row',{staticClass:"form-group d-flex align-items-center"},[_c('b-col',{attrs:{"md":"2","lg":"3"}},[_c('label',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("configuration.smtpHost"))+" ")])]),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-input',{attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.smtpHost),callback:function ($$v) {_vm.smtpHost=$$v},expression:"smtpHost"}})],1),_c('b-col',{attrs:{"md":"2","lg":"3"}},[_c('label',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("configuration.smtpPort"))+" ")])]),_c('b-col',{attrs:{"md":"2"}},[_c('validation',{attrs:{"validations":_vm.$v.smtpPort},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.smtpPort),callback:function ($$v) {_vm.smtpPort=$$v},expression:"smtpPort"}})}}])})],1),(_vm.smtpConnection)?_c('b-col',{attrs:{"md":"1"}},[_c('font-awesome-icon',{staticClass:"text-persian-green-500",attrs:{"icon":['fat', 'circle-check'],"size":"lg"}})],1):_vm._e()],1),_c('b-row',{staticClass:"form-group d-flex align-items-center"},[_c('b-col',{attrs:{"md":"2","lg":"3"}},[_c('label',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("configuration.imapHost"))+" ")])]),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-input',{attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.imapHost),callback:function ($$v) {_vm.imapHost=$$v},expression:"imapHost"}})],1),_c('b-col',{attrs:{"md":"2","lg":"3"}},[_c('label',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("configuration.imapPort"))+" ")])]),_c('b-col',{attrs:{"md":"2"}},[_c('validation',{attrs:{"validations":_vm.$v.imapPort},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.imapPort),callback:function ($$v) {_vm.imapPort=$$v},expression:"imapPort"}})}}])})],1),(_vm.imapConnection)?_c('b-col',{attrs:{"md":"1"}},[_c('font-awesome-icon',{staticClass:"text-persian-green-500",attrs:{"icon":['fat', 'circle-check'],"size":"lg"}})],1):_vm._e()],1),_c('b-row',{staticClass:"form-group"},[_c('b-col',{attrs:{"md":"11"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('save-button',{staticClass:"fsc-btn-border",attrs:{"disabled":_vm.hasNotPermissionWrite},on:{"click":_vm.onSubmit}})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }