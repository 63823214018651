








































































































































































































































import { Component, Prop } from "vue-property-decorator";
import instructorPlaceholder from "@/assets/teacherimgplaceholder.png";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import Validation from "@/components/Validation.vue";
import PlzInput from "@/components/Field/PlzInput.vue";
import { CREATE, EDIT } from "@/constants/FormType";
import FscPlaces from "@/components/Field/FscPlaces.vue";
import AuthImage from "@/directives/AuthImage";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Datepicker from "@/components/Datepicker.vue";
import { mixins } from "vue-class-component";
import CompanyMixin from "@/mixins/CompanyMixin";
import { remove } from "@/utils/localForageUtil";
import debounce from "lodash/debounce";

@Component({
  components: {
    Datepicker,
    FscSimpleCard,
    FscPlaces,
    PlzInput,
    AbortButton,
    SaveButton,
    Validation,
  },
  directives: {
    AuthImage: new AuthImage(),
  },
})
export default class InstructorBaseData extends mixins(CompanyMixin) {
  public name = "InstructorBaseData";

  public profileSrc: any = null;
  public file = null;

  @Prop()
  public data!: any;

  @Prop()
  public v!: any;

  @Prop({ type: String, default: () => CREATE })
  public type: any;

  @Prop()
  public fileData!: any;

  @Prop({ type: Array, default: () => [] })
  public roles!: any;

  @Prop({ type: Array, default: () => [] })
  public licenseClasses!: any;

  @Prop({ type: String, default: () => "" })
  public avatarUrl!: string;

  public debounce: any;

  public mounted() {
    this.debounce = debounce((callback: any) => callback(), 100);
  }

  public onUpload(): void {
    if (this.hasNotPermissionWrite) return;
    (this.$refs.file as HTMLFormElement).click();
    this.debounce(() => {
      remove(this.avatarUrl);
    });
  }

  public onFileChange(): void {
    const file = (this.$refs.file as HTMLFormElement).files[0];
    this.profileSrc = URL.createObjectURL(file);
    this.file = file;
    this.fileData.file = file;
  }

  public get rolesOptions(): Array<any> {
    return this.roles;
  }

  public get licenseClassesOptions(): Array<string> {
    return this.licenseClasses;
  }

  public get typeEdit(): boolean {
    return this.type === EDIT;
  }

  public get isRoleTypeInstructor(): boolean {
    return false;
  }

  public created(): void {
    this.profileSrc = instructorPlaceholder;
  }
}
