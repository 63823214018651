


















































import { Component, Prop, Watch } from "vue-property-decorator";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import FormCard from "@/components/FormCard.vue";
import { namespace } from "vuex-class";
import WorkHoursPanel from "@/components/WorkHours/WorkHoursPanel.vue";
import Validation from "@/components/Validation.vue";
import PlzInput from "@/components/Field/PlzInput.vue";
import NextButton from "@/components/Button/NextButton.vue";
import InstructorBaseData from "@/views/Instructor/InstructorBaseData.vue";
import InstructorSettings from "@/views/Instructor/InstructorSettings.vue";
import { CREATE, EDIT } from "@/constants/FormType";
import FscPageHeader from "@/components/FscPageHeader.vue";
import { Validations } from "vuelidate-property-decorators";
import { maxLength, required, email, minLength, integer, requiredIf } from "vuelidate/lib/validators";
import MailConfiguration from "@/views/Configuration/MailConfiguration.vue";
import { mixins } from "vue-class-component";
import CompanyMixin from "@/mixins/CompanyMixin";
import { formatInstructorName } from "@/utils/NameUtil";

const RoleModule = namespace("role");
const InstructorModule = namespace("instructor");
const LicenseClassModule = namespace("license-class");

@Component({
  components: {
    MailConfiguration,
    FscPageHeader,
    InstructorSettings,
    InstructorBaseData,
    NextButton,
    PlzInput,
    WorkHoursPanel,
    FormCard,
    AbortButton,
    SaveButton,
    Validation,
  },
})
export default class InstructorForm extends mixins(CompanyMixin) {
  public name = "InstructorForm";

  @Prop()
  public data!: any;

  @Prop({ type: String, default: () => CREATE })
  public type: any;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  public activeTab = 0;

  public tabs = [
    {
      label: this.$t("general.base_data"),
    },
    {
      label: this.$t("general.settings"),
      disabled: !this.typeEdit,
    },
    {
      label: this.$t("configuration.mail"),
      disabled: !this.typeEdit,
    },
  ];

  public baseData = {
    // instructor
    initials: null,
    role: null,
    furtherEducationMonth: null,
    licenseClasses: [],
    firstName: null,
    lastName: null,
    privatePhone: null,
    mobilePhone: null,
    email: null,
    password: null,
    postalCode: null,
    location: null,
    streetAndNumber: null,
    birthDate: null,
    birthPlace: null,
    note: null,
  };

  public settingsData = {
    // settings
    showOnlyAssignedStudents: false,
    daysOfWeek: [],
    calendarAccessUsers: [],
    calendarWriteAccessUsers: [],
    defaultDrivingHourMinutes: 90,
  };

  public fileData = {
    file: null,
  };

  protected dayWorkHours = [];

  @RoleModule.Action("findAll")
  public roleFilter: any;

  @RoleModule.Getter("getDataList")
  public roles: any;

  @InstructorModule.Action("filter")
  public filterInstructorsAction: any;

  @InstructorModule.Getter("getDataList")
  public instructors: any;

  @InstructorModule.Action("workHours")
  public workHoursAction: any;

  @InstructorModule.Getter("getWorkHours")
  public workHours: any;

  @LicenseClassModule.Action("findAll")
  public licenseClassFindAll: any;

  @LicenseClassModule.Getter("getDataList")
  public licenseClasses: any;

  public roleOptions: any = [];

  public async onSubmit(): Promise<void> {
    if (this.hasNotPermissionWrite) return;

    this.$v.$touch();

    if (this.$v.baseData.$invalid) {
      this.activeTab = 0;
      return;
    }

    if (this.$v.settingsData.$invalid) {
      this.activeTab = 1;
      return;
    }

    this.$emit("on-submit", {
      data: Object.assign(this.baseData, this.settingsData),
      fileData: this.fileData,
      dayWorkHours: this.dayWorkHours,
    });
  }

  public get typeEdit(): boolean {
    return this.type === EDIT;
  }

  public get typeCreate(): boolean {
    return this.type === CREATE;
  }

  public changeTab(index: number) {
    this.activeTab = index;
  }

  protected onAbort(): void {
    this.$router.push({ name: "Instructors" });
    this.$v.$reset();
    if (this.type === CREATE) {
      this.resetBaseData();
    }
  }

  public resetBaseData() {
    this.baseData = {
      // instructor
      initials: null,
      role: null,
      furtherEducationMonth: null,
      licenseClasses: [],
      firstName: null,
      lastName: null,
      privatePhone: null,
      mobilePhone: null,
      email: null,
      password: null,
      postalCode: null,
      location: null,
      streetAndNumber: null,
      birthDate: null,
      birthPlace: null,
      note: null,
    };
  }
  @Validations()
  public validations(): Record<string, any> {
    return {
      baseData: {
        initials: { required, maxLength: maxLength(4) },
        role: { required },
        firstName: { required },
        lastName: { required },
        email: { required, email },
        password: { minLength: minLength(4) },
        postalCode: { integer, maxLength: maxLength(5) },
      },
      settingsData: {
        // settings
        showOnlyAssignedStudents: {
          required: requiredIf(() => {
            return this.typeEdit;
          }),
        },
        calendarAccessUsers: {
          /*required: requiredIf(() => {
            return this.typeEdit;
          }),*/
        },
        calendarWriteAccessUsers: {},
        defaultDrivingHourMinutes: {
          required: requiredIf(() => {
            return this.typeEdit;
          }),
        },
      },
    };
  }

  @Watch("data", { immediate: true, deep: true })
  public initData(data: any): void {
    if (data) {
      // Instructor
      this.baseData.initials = data.initials;
      this.baseData.role = data.role;
      this.baseData.firstName = data.firstName;
      this.baseData.lastName = data.lastName;
      this.baseData.privatePhone = data.privatePhone;
      this.baseData.mobilePhone = data.mobilePhone;
      this.baseData.location = data.location;
      this.baseData.email = data.email;
      this.baseData.postalCode = data.postalCode;
      this.baseData.streetAndNumber = data.streetAndNumber;
      this.baseData.birthDate = data.birthDate;
      this.baseData.birthPlace = data.birthPlace;
      this.baseData.note = data.note;
      this.baseData.licenseClasses = data.licenseClasses;
      this.baseData.furtherEducationMonth = data.furtherEducationMonth;

      // Settings
      this.settingsData.showOnlyAssignedStudents = data.showOnlyAssignedStudents ? data.showOnlyAssignedStudents : false;
      this.settingsData.calendarAccessUsers = data.calendarAccessUsers;
      this.settingsData.calendarWriteAccessUsers = data.calendarWriteAccessUsers;
      this.settingsData.defaultDrivingHourMinutes = data.defaultDrivingHourMinutes ? data.defaultDrivingHourMinutes : 90;
    }
  }

  protected daysOfWeekHandle(data: any): void {
    this.dayWorkHours = data;
  }

  public mounted(): void {
    if (this.userId) this.workHoursAction(this.userId);
    this.filterInstructorsAction({ resource: "instructor", filter: { archived: false } });
    this.licenseClassFindAll({ resource: "license-classes" });
    this.roleFilter({ resource: "roles" });
  }

  public get userId(): number {
    return Number(this.$route.params.id);
  }

  public get avatarUrl(): string {
    if (this.userId) return `users/avatar?userId=${this.userId}`;
    return "";
  }

  public get pageHeaderTitle(): string {
    return this.typeCreate ? this.$t("sidebar.instructors_new").toString() : formatInstructorName(this.data?.firstName, this.data?.lastName);
  }

  @Watch("roles", { deep: true, immediate: true })
  public onRolesLoad(roles: any) {
    if (roles) {
      this.roleOptions = roles.filter((role: any) => {
        if (role.roleType && role.roleType.id !== 3) {
          return role;
        }
      });
    }
  }
}
