



























































































import { Component, Prop, Watch } from "vue-property-decorator";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import WorkHoursPanel from "@/components/WorkHours/WorkHoursPanel.vue";
import Validation from "@/components/Validation.vue";
import PlzInput from "@/components/Field/PlzInput.vue";
import NextButton from "@/components/Button/NextButton.vue";
import { IDayWorkHour } from "@/interfaces/IDayWorkHour";
import { CREATE } from "@/constants/FormType";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { mixins } from "vue-class-component";
import CompanyMixin from "@/mixins/CompanyMixin";
import { formatInstructorName } from "@/utils/NameUtil";

@Component({
  components: {
    FscSimpleCard,
    NextButton,
    PlzInput,
    WorkHoursPanel,
    AbortButton,
    SaveButton,
    Validation,
  },
})
export default class InstructorSettings extends mixins(CompanyMixin) {
  public name = "InstructorSettings";

  @Prop()
  public data!: any;

  @Prop({ type: String, default: () => CREATE })
  public type: any;

  @Prop()
  public v!: any;

  @Prop({ type: Array, default: () => [] })
  public instructors: any;

  @Prop({ type: Array, default: () => [] })
  public workHours: any;

  public daysOfWeek: Array<IDayWorkHour> = [];

  public get hoursPlanerAppTeachers(): Array<any> {
    return [];
  }

  private get instructorsFiltered(): Array<any> {
    if (!this.instructors) {
      return [];
    }
    if (!this.$route.params.id) {
      return this.instructors;
    }
    return this.instructors.filter((instr: any) => instr.id != this.$route.params.id);
  }

  public builtDaysOfWeek(daysOfWeek: Array<IDayWorkHour>): void {
    this.daysOfWeek = daysOfWeek;
    this.$emit("days-of-week", daysOfWeek);
  }

  @Watch("workingHours")
  public initWorkingHours(workingHours: any): void {
    if (workingHours) {
      this.daysOfWeek = workingHours;
    }
  }

  protected instructorsLabel(instructor: any): string {
    if (!instructor) {
      return "";
    }
    return instructor.name ? instructor.name : formatInstructorName(instructor.firstName, instructor.lastName);
  }
}
