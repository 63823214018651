var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fsc-simple-card',{staticClass:"h-100",attrs:{"content-class":"py-1 pt-4"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-md-5"},[_c('p',[_vm._v(_vm._s(_vm.$t("general.calendar_read_access_users")))])]),_c('div',{staticClass:"col-md-6"},[_c('Validation',{attrs:{"validations":_vm.v.settingsData.calendarAccessUsers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"multiple":true,"track-by":"id","getOptionLabel":_vm.instructorsLabel,"options":_vm.instructorsFiltered,"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.data.calendarAccessUsers),callback:function ($$v) {_vm.$set(_vm.data, "calendarAccessUsers", $$v)},expression:"data.calendarAccessUsers"}})}}])})],1)]),_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-md-5"},[_c('p',[_vm._v(_vm._s(_vm.$t("general.calendar_write_access_users")))])]),_c('div',{staticClass:"col-md-6"},[_c('Validation',{attrs:{"validations":_vm.v.settingsData.calendarWriteAccessUsers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"multiple":true,"track-by":"id","getOptionLabel":_vm.instructorsLabel,"options":_vm.instructorsFiltered,"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.data.calendarWriteAccessUsers),callback:function ($$v) {_vm.$set(_vm.data, "calendarWriteAccessUsers", $$v)},expression:"data.calendarWriteAccessUsers"}})}}])})],1)]),_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-md-5"},[_c('p',[_vm._v(_vm._s(_vm.$t("general.standard_driving_hour_duration"))+" *")])]),_c('div',{staticClass:"col-md-6"},[_c('Validation',{attrs:{"validations":_vm.v.settingsData.defaultDrivingHourMinutes},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return _c('b-input',{class:{
                'is-invalid': invalid,
              },attrs:{"type":"number","disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.data.defaultDrivingHourMinutes),callback:function ($$v) {_vm.$set(_vm.data, "defaultDrivingHourMinutes", $$v)},expression:"data.defaultDrivingHourMinutes"}})}}])})],1)]),_c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-md-5"},[_c('p',[_vm._v(_vm._s(_vm.$t("general.show_students"))+" *")])]),_c('div',{staticClass:"col-md-6"},[_c('Validation',{attrs:{"validations":_vm.v.settingsData.showOnlyAssignedStudents},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var invalid = ref.invalid;
return _c('b-form-group',{class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite}},[_c('b-form-radio',{attrs:{"name":"show_students","value":"false"},model:{value:(_vm.data.showOnlyAssignedStudents),callback:function ($$v) {_vm.$set(_vm.data, "showOnlyAssignedStudents", $$v)},expression:"data.showOnlyAssignedStudents"}},[_vm._v(" "+_vm._s(_vm.$t("general.show_all_students"))+" ")]),_c('b-form-radio',{attrs:{"name":"show_students","value":"true"},model:{value:(_vm.data.showOnlyAssignedStudents),callback:function ($$v) {_vm.$set(_vm.data, "showOnlyAssignedStudents", $$v)},expression:"data.showOnlyAssignedStudents"}},[_vm._v(" "+_vm._s(_vm.$t("general.show_only_my_students"))+" ")])],1)}}])})],1)])]),_c('b-col',{attrs:{"md":"5"}},[_c('work-hours-panel',{attrs:{"data":_vm.workHours,"header-title":_vm.$t('general.work_corridor'),"disabled":_vm.hasNotPermissionWrite},on:{"built-days-of-week":_vm.builtDaysOfWeek}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }