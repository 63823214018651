







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NextButton extends Vue {
  public name = "NextButton";

  @Prop({ default: () => "Weiter", type: String })
  public label!: string;
}
