







































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { numeric, required, email } from "vuelidate/lib/validators";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import TestButton from "@/components/Button/TestButton.vue";
import { namespace } from "vuex-class";
import { IEmailConfig } from "@/interfaces/IEmailConfig";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { mixins } from "vue-class-component";
import EmailConfigRequestMixin from "@/mixins/Request/EmailConfigRequestMixin";
import CreateButton from "@/components/Button/CreateButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import CompanyMixin from "@/mixins/CompanyMixin";

const EmailConfigurationModule = namespace("email-config");

@Component({
  components: {
    RemoveButton,
    CreateButton,
    FscSimpleCard,
    TestButton,
    SaveButton,
    AbortButton,
    Validation,
    FormCard,
  },
})
export default class MailConfiguration extends mixins(EmailConfigRequestMixin, CompanyMixin) {
  public name = "MailConfiguration";

  @EmailConfigurationModule.Action("test")
  public testAction: any;

  @EmailConfigurationModule.Action("update")
  public update: any;

  @EmailConfigurationModule.Getter("getId")
  public updatedId!: number;

  @EmailConfigurationModule.Action("del")
  public emailsConfigDelete!: (options: ICrudOptions) => Promise<void>;

  @EmailConfigurationModule.Getter("getDeleted")
  public emailsConfigDeleted!: boolean;

  @EmailConfigurationModule.Action("getSuccess")
  public emailConfigSuccess: any;

  @EmailConfigurationModule.Getter("getTestItem")
  public testItem: any;

  @Prop({ type: Number })
  public userId!: number;

  public smtpHost = "";
  public smtpPort = 0;
  public imapHost = "";
  public imapPort = 0;
  public email = "";
  public username = "";
  public password = "";
  public sender = "";
  public mailBox: IEmailConfig | null = null;

  public smtpConnection = false;
  public imapConnection = false;

  @Validations()
  public validations(): Record<string, any> {
    return {
      imapPort: { numeric },
      smtpPort: { numeric },
      email: { required, email },
      username: { required },
      password: { required },
      sender: { required },
    };
  }

  public async onDelete(): Promise<void> {
    if (this.hasNotPermissionWrite) return;
    if (this.mailBox && this.mailBox.id) {
      await this.emailsConfigDelete({
        id: this.mailBox.id,
        resource: "email-config",
        descriptionField: "",
      });

      if (this.emailsConfigDeleted) {
        this.fetchEmailsConfig();
        this.mailBox = null;
        this.resetFields();
        this.$v.$reset();
      }
    }
  }

  public async onSubmit(): Promise<void> {
    if (this.hasNotPermissionWrite) return;

    this.$v.$touch();

    if (this.$v.$invalid) return;

    const data = this.buildObject();

    await this.update({
      id: "",
      data: { ...data },
      resource: "email-config",
    });

    if (this.emailConfigSuccess) {
      await this.fetchEmailsConfig();
      const emailConfig = this.emailsConfigByUser.find((x: IEmailConfig) => x.id === this.updatedId);
      if (emailConfig) {
        this.mailBox = emailConfig;
      }
    }
  }

  public mounted(): void {
    this.fetchEmailsConfig();

    const emailsConfigWatcher = this.$watch("emailsConfigByUser", (value: Array<IEmailConfig>) => {
      if (value.length) {
        this.mailBox = value[0];
        this.onInputMailBox(value[0]);
      }
      // unwatch
      emailsConfigWatcher();
    });
  }

  protected async fetchEmailsConfig(): Promise<void> {
    await this.emailsConfigByUserId(this.userId);
  }

  public buildObject(): IEmailConfig {
    return {
      id: this.mailBox?.id || null,
      userId: this.userId,
      smtpHost: this.smtpHost,
      smtpPort: this.smtpPort,
      imapHost: this.imapHost,
      imapPort: this.imapPort,
      email: this.email,
      username: this.username,
      password: this.password,
      sender: this.sender,
    };
  }

  @Watch("testItem")
  public testItemWatcher(testItem: any): void {
    this.imapConnection = testItem.imapConnection;
    this.smtpConnection = testItem.smtpConnection;
  }

  public onInputMailBox(emailConfig: IEmailConfig): void {
    if (!Array.isArray(emailConfig) && emailConfig) {
      this.smtpHost = emailConfig.smtpHost;
      this.smtpPort = emailConfig.smtpPort;
      this.imapHost = emailConfig.imapHost;
      this.imapPort = emailConfig.imapPort;
      this.email = emailConfig.email;
      this.username = emailConfig.username;
      this.password = emailConfig.password;
      this.sender = emailConfig.sender;
    } else {
      this.resetFields();
      this.$v.$reset();
    }
  }

  public resetFields(): void {
    this.smtpHost = "";
    this.smtpPort = 587;
    this.imapHost = "";
    this.imapPort = 993;
    this.email = "";
    this.username = "";
    this.password = "";
    this.sender = "";
  }

  public onCreate(): void {
    if (this.hasNotPermissionWrite) return;
    this.mailBox = null;
    this.resetFields();
    this.$v.$reset();
  }
}
