




import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import InstructorForm from "./InstructorForm.vue";

const InstructorModule = namespace("instructor");

@Component({
  components: {
    InstructorForm,
  },
})
export default class InstructorCreate extends Vue {
  public name = "InstructorCreate";

  @InstructorModule.Action("create")
  public create: any;

  @InstructorModule.Getter("getSuccess")
  public success: any;

  @InstructorModule.Getter("getIsLoading")
  public getIsLoading: any;

  @InstructorModule.Getter("getDataItem")
  public dataItem: any;

  @InstructorModule.Getter("getId")
  public getId: any;

  @InstructorModule.Action("workHoursUpdate")
  public workHoursUpdateAction: any;

  @InstructorModule.Action("userAvatarUpload")
  protected userAvatarUpload: any;

  public async onSave(data: any): Promise<void> {
    await this.create({
      data: data.data,
      resource: "instructor",
      descriptionField: "email",
    });

    if (data.fileData.file && this.getId) {
      const formData = new FormData();
      formData.append("file", data.fileData.file);
      formData.append("userId", this.getId);
      this.userAvatarUpload(formData);
    }

    if (this.success) {
      await this.$router.push({ name: "Instructors" });
    }
  }
}
